import styles from "./ChatCollectionSelector.module.scss";
import React, {useCallback, useEffect, useState} from "react";
import FilesApi, {Collection} from "../../api/Files";
import {CheckmarkIcon, DownCaretIcon, FolderIcon, ListIcon} from "../common/Icons";
import clsx from "clsx";

interface ChatCollectionSelectorProps {
  onSelect: (item: Collection | undefined) => void;
  selectedCollection?: Collection;
}

function ChatCollectionSelector({ onSelect, selectedCollection}: ChatCollectionSelectorProps) {
  const [collections, setCollections] = useState<Collection[]>();
  useEffect(() => {
    if (!collections) {
      FilesApi.getAllCollections()
        .then((data) => {
          setCollections(data);
        });
    }
  }, [collections, setCollections]);

  const [isOpen, setIsOpen] = useState(false);
  const toggleIsOpen = useCallback(() => {
    setIsOpen(!isOpen);
  }, [setIsOpen, isOpen]);

  const handleItemClick =  useCallback((item: Collection | undefined) => () => {
    onSelect(item);
    setIsOpen(false);
  }, [onSelect, setIsOpen]);

  if (!collections) {
    return null;
  }

  const buttonClasses = clsx(styles.button, {
    [styles.active]: isOpen
  });

  return (
    <div className={styles.dropdown}>
      <button onClick={toggleIsOpen} className={buttonClasses}>
        {selectedCollection ?  selectedCollection.name : "コレクション選択"}
        <DownCaretIcon color={isOpen ? "white": "black"} size={16}/>
      </button>
      {
        isOpen && (
          <ul className={styles.dropdownMenu}>
            <li onClick={handleItemClick(undefined)} className={styles.option}>
              <div className={styles.name}>
                <ListIcon color="black" size={16}/>
                すべてのファイル
              </div>
            </li>
            {collections.map((item, index) => (
              <li key={index} onClick={handleItemClick(item)} className={styles.option}>
                <div className={styles.name}>
                  <FolderIcon color="black" size={16}/>
                  {item.name}
                </div>

                {selectedCollection?.id === item.id && (
                  <CheckmarkIcon color="blue" size={16}/>
                )}
              </li>
            ))}
          </ul>
        )
      }
    </div>
  );
};

export default ChatCollectionSelector;
