import React, { useState, useEffect } from 'react';

function ErrorBoundary({ children }: { children: React.ReactNode }) {
  const [error, setError] = useState<Error | null>(null);
  const [errorInfo, setErrorInfo] = useState<string | null>(null);

  useEffect(() => {
    window.addEventListener('error', handleGlobalError);
    return () => {
      window.removeEventListener('error', handleGlobalError);
    };
  }, []);

  const handleGlobalError = (event: ErrorEvent) => {
    setError(event.error);
    setErrorInfo(event.error?.stack || 'No stack trace available');
  };

  const handleResetError = () => {
    setError(null);
    setErrorInfo(null);
  };

  if (error) {
    return (
      <div>
        <h2>エラーが発生しました</h2>
        <p>{error && error.toString()}</p>
        <p>エラーの詳細：{errorInfo}</p>
        <button onClick={handleResetError}>リセットエラー</button>
      </div>
    );
  }

  return <>{children}</>;
}

export default ErrorBoundary;
