import React, {SetStateAction, useState,useEffect} from "react";
import styles from "./ChatSourceElement.module.scss";
import clsx from "clsx";
import ChatApi from "../../api/Files";
import {Source} from "../../api/Chat";
import CounterStore from '../../stores/CounterStore';
export interface DocumentFragment {
  id: string;
  filename: string;
  userId: string;
}


const highlightRelevantSection = (source: Source) => {
  return (
    <div>
      {source.chunkText.slice(0, source.start)}
      <b>{source.chunkText.slice(source.start, source.end)}</b>
      {source.chunkText.slice(source.end, source.chunkText.length)}
    </div>
  )
};

interface SourceButtonProps {
  source: Source;
  sourceNumber: number;
  selectedSource: Source | null;
  setSelectedSource: React.Dispatch<SetStateAction<Source | null>>;
}

function SourceButton({ source, sourceNumber, selectedSource, setSelectedSource }: SourceButtonProps) {
  const buttonClasses = clsx(styles.button, {
    [styles.buttonSelected]: (selectedSource === source && CounterStore.state.selectedSource)
  });
  const circleClasses = clsx(styles.circle, {
    [styles.circleSelected]: (selectedSource === source && CounterStore.state.selectedSource)
  });
  return (
    <div className={buttonClasses} onClick={() => {
      CounterStore.setSelectedSource(true)
      setSelectedSource(selectedSource === source ? null : source);
    }}>
      <div className={circleClasses}>{sourceNumber + 1}</div>
      <div className={styles.buttonText}>{source.chunkText.slice(0, 14)}</div>
    </div>
  );
}

function ChatSourceElement({ sources }: { sources?: Source[] }) {
  const [selectedSource, setSelectedSource] = useState<Source | null>(null);
  const _selectedSource = CounterStore.state.selectedSource
  const downloadHandler = (selectedSource:any) => {
    const {document} = selectedSource
    const {id,filename} = document ||{}
    if (id) {
      ChatApi.downloadSourceDocument(id,filename).then();
    }
  };
  useEffect(() => {
    !_selectedSource && setSelectedSource(null)
  }, [_selectedSource]);
  return (
    <div className={styles.sources}>
      <div className={styles.buttons}>
        {
          sources?.map((s, i) =>
            <SourceButton
              key={i}
              source={s}
              sourceNumber={i}
              selectedSource={selectedSource}
              setSelectedSource={setSelectedSource}
            />
          )
        }
      </div>
      {
       ( selectedSource && _selectedSource) &&
          <div className={styles.source}>
            <div className={styles.header}>Chunk #{selectedSource.chunk}</div>
            <div className={styles.body}>{highlightRelevantSection(selectedSource)}</div>
            <div className={styles.footer}>
              <div className={styles.footerName}>SOURCE:</div>
              <div className={styles.footerText} onClick={() => downloadHandler(selectedSource)}>
                {selectedSource.document.filename}
              </div>
            </div>
          </div>
      }
    </div>
  );
}

export default ChatSourceElement;