import {Collection, Document} from "../../api/Files";
import styles from "./FileRow.module.scss";
import {FileIcon, MoveFolderIcon, TrashIcon} from "../common/Icons";
import clsx from "clsx";
import React, {useCallback, useMemo} from "react";
import FilesApi from "../../api/Files";
import {useDialog} from "../common/Dialog";
import CollectionSelectionDialog from "../common/CollectionSelectionDialog";

interface FileRowProps {
  document: any;
  onDelete: () => void;
  handleCheckboxClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  checked: boolean;
  onDocumentMove: (document: Document[], targetCollectionId: string) => void;
  collections: Collection[];
  hideActions?: boolean;
}

function FileRow({document, onDelete, handleCheckboxClick, checked, onDocumentMove, collections, hideActions}: FileRowProps) {
  const { created, filename } = document;  
  
  const handleDelete = useCallback(() => {
    onDelete();
    FilesApi.deleteDocument(document.id).then();
  }, [onDelete, document]);

  const handleMoveSubmit = (collectionId: string) => {
    onDocumentMove([document], collectionId);
  };

  const collectionName = () => {
    return collections.find((collection) => collection.id === (document.collectionId || document.collection_id))?.name;
    
  }
  const {show, toggleShow} = useDialog();

  return (
    <div className={styles.row}>
      <CollectionSelectionDialog
        collections={collections}
        headerText={`「${document.filename}」を別のコレクションに移動します`}
        onSubmit={handleMoveSubmit}
        show={show}
        toggleShow={toggleShow}
      />

      <input
        type="checkbox"
        name={document.id}
        className={styles.checkbox}
        onChange={handleCheckboxClick}
        checked={checked}
      />
      <div className={clsx(styles.name, styles.text)}>
        <div className={styles.icon}>
          <FileIcon color="light-grey" size={16}/>
        </div>
        {filename}
      </div>

      <div className={styles.text}>
        {created?.getFullYear()}年{created?.getMonth() + 1}月{created?.getDate()}日
      </div>

      <div className={styles.text}>
        {collectionName()}
      </div>

      <div className={styles.actions}>
        {
          !hideActions && (
            <>
              <div className={styles.delete} onClick={toggleShow}>
                <MoveFolderIcon color="light-grey" size={16}/>
              </div>

              <div className={styles.delete} onClick={handleDelete}>
                <TrashIcon color="light-grey" size={16}/>
              </div>
            </>
          )
        }
      </div>
    </div>
  );
}

export default FileRow;