import {backend} from "./AxiosSetup";

export interface Collection {
  name: string;
  id: string;
  userId: string;
  created: Date;
  updated: Date;
  documents: Document[];
}

function parseCollection(json: any): Collection {
  return {
    name: json.name,
    id: json.id,
    userId: json.userId,
    created: new Date(json.created),
    updated: new Date(json.updated),
    documents: json.documents.map((document: any) => parseDocument(document))
  };
}

export interface Document {
  id: string;
  filename: string;
  mimetype: string;
  created: Date;
  userId: string;
  collectionId?: string;
}

export function parseDocument(json: any): Document {
  return {
    id: json.id,
    filename: json.filename,
    mimetype: json.mimetype,
    userId: json.userId,
    created: new Date(json.created),
    collectionId: json.collectionId
  };
}

const FilesApi = {
  getAllDocuments(): Promise<Document[]> {
    return backend
      .get("/documents/")
      .then(({ data }) => data.map((json: any) => {
        return parseDocument(json)
      }));
  },
  uploadDocument(file: FormData): Promise<Document> {
    return backend
      .post("/documents/", file, {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      })
      .then(({ data }) => {
      //   if (data.code !== 1) {
      //    return parseDocument(data)
        //  }
        return parseDocument(data)
      });
  },
  deleteDocument(documentId: string): Promise<void> {
    return backend
        .delete(`/documents/${documentId}`)
        .then();
  },
  downloadSourceDocument(documentId: string,filename:string): Promise<void> {
    return backend
      .get(`/documents/${documentId}/download`, {
        responseType: 'blob'
      })
      .then(({ data }) => {
        const href = window.URL.createObjectURL(data);
        const anchorElement = document.createElement('a');

        anchorElement.href = href;
        anchorElement.download = filename;

        document.body.appendChild(anchorElement);
        anchorElement.click();

        document.body.removeChild(anchorElement);
        window.URL.revokeObjectURL(href);
      });
  },
  getAllCollections(): Promise<[]> {
    return backend
      .get(`/collections/`)
      .then(({ data }: any) => data.map((json: any) => parseCollection(json)));
  },
  getCollection(id: string): Promise<Collection> {
    return backend
      .get(`/collections/${id}`)
      .then(({ data }: any) => parseCollection(data));
  },
  createCollection(name: string, documentIds: string[]): Promise<Collection> {
    return backend
      .post("/collections/", {name: name, documentIds: documentIds})
      .then(({ data }) => {
        return parseCollection(data);
      });
  },
  getAllDocumentsForCollection(collectionId: string): Promise<Document[]> {
    return backend
      .get(`/collections/${collectionId}/documents`)
      .then(({ data }) => data.map((json: any) => parseDocument(json)));
  },
  deleteCollection(collectionId: string): Promise<void> {
    return backend
      .delete(`/collections/${collectionId}`, {})
      .then();
  },
  renameCollection(collectionId: string, name: string): Promise<Collection> {
    return backend
      .post(`/collections/${collectionId}/rename`, {name: name})
      .then(({ data }) => parseCollection(data));
  },
  updateCollectionDocuments(collectionId: string, documentIds: string[]): Promise<Collection> {
    return backend
      .put(`/collections/${collectionId}/documents`, { documentIds: documentIds })
      .then(({ data }) => parseCollection(data));
  }
}

export default FilesApi;