import React, {memo, ReactNode, useCallback, useState} from "react";
import styles from "./Dialog.module.scss";
import {IconProps} from "./Icons";
import clsx from "clsx";

export function useDialog() {
  const [show, setShow] = useState(false);
  const toggleShow = useCallback(() => {
    setShow(!show);
  }, [setShow, show]);

  return {show, toggleShow};
}

interface DialogProps {
  headerIcon: (props: IconProps) => React.ReactElement;
  headerText: string;
  title: string;
  onSubmit: () => void;
  onClose: () => void;
  show: boolean;
  children: ReactNode;
  submitText: string;
  submitIcon: (props: IconProps) => React.ReactElement;
  submitIsDisabled?: boolean;
}

function Dialog(
  {
    headerIcon,
    headerText,
    onClose,
    show,
    onSubmit,
    title,
    children,
    submitIcon,
    submitText,
    submitIsDisabled
  }: DialogProps
) {
  const HeaderIcon = headerIcon;
  const SubmitIcon = submitIcon;

  if (!show) {
    return null;
  }

  const submitClasses = clsx(styles.submit, {
    [styles.disabled]: submitIsDisabled
  });

  return (
    <div className={styles.background} onClick={onClose}>
      <div className={styles.dialog} onClick={(e) => e.stopPropagation()}>
        <div className={styles.header}>
          {HeaderIcon && <HeaderIcon size={16} color="grey"/>}
          {headerText}
        </div>

        <div className={styles.main}>
          <div className={styles.title}>{title}</div>
          {children}
        </div>

        <div className={styles.footer}>
          <button className={styles.cancel} onClick={onClose}>
            キャンセル
          </button>

          <button className={submitClasses} onClick={onSubmit}>
            {SubmitIcon && <SubmitIcon color="white" size={16}/>}
            {submitText}
          </button>
        </div>
      </div>
    </div>
  );
}

export default memo(Dialog);