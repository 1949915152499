import styles from "./Nav.module.scss";
import GPT from "../../assets/GPT-icon.svg";
import React, {memo, useCallback} from "react";
import {useAuth0} from "@auth0/auth0-react";
import {Link, useLocation} from "react-router-dom";
import clsx from "clsx";
import {HomeIcon, FolderIcon, LogoutIcon, IconProps} from "./Icons";

function Nav() {
  const { logout } = useAuth0();
  const handleLogout = useCallback(() => {
    logout({ logoutParams: { returnTo: window.location.origin } })
  }, [logout]);
  
  return (
    <div className={styles.nav}>
      <div className={styles.top}>
        <img src={GPT} alt="GPT"/>
        <div className={styles.line}/>
        <NavButton icon={HomeIcon} path="/"/>
        <NavButton icon={FolderIcon} path="/files"/>
      </div>

      <NavButton icon={LogoutIcon} onClick={handleLogout}/>
    </div>
  );
}

interface NavButtonProps {
  icon: (props: IconProps) => React.ReactElement;
  path?: string;
  onClick?: () => void;
}

function NavButton({ icon, path, onClick }: NavButtonProps) {
  const location = useLocation();
  const isActive = location.pathname === path;
  const classes = clsx(styles.button, {
    [styles.active]: isActive
  });
  const Icon = icon;

  if (path) {
    return (
      <Link to={path} className={classes} onClick={onClick}>
        <Icon color={isActive ? "white" : "black"} size={32}/>
      </Link>
    );
  }
  
  return (
    <div className={classes} onClick={onClick}>
      <Icon color={isActive ? "white" : "black"} size={32}/>
    </div>
  );
}

export default memo(Nav);