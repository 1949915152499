import React from "react";
import styles from "./ChatHistoryButton.module.scss";
import {ChatLog} from "../../api/Chat";
import clsx from "clsx";
import ChevronBlackIcon from "../../assets/chevron-black.svg";
import ChevronWhiteIcon from "../../assets/chevron-white.svg";

interface ChatHistoryButtonProps {
  log: ChatLog;
  isActive: boolean;
  onClick: () => void;
}

function ChatHistoryButton({ log, isActive, onClick }: ChatHistoryButtonProps) {
  const classes = clsx(styles.button, {
    [styles.active]: isActive
  });

  return (
    <div className={classes} onClick={onClick}>
      <img className={styles.chevron} src={isActive ? ChevronWhiteIcon : ChevronBlackIcon} alt=""/>
      {log.title}
    </div>
  );
}

export default ChatHistoryButton;