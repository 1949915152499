import React, {memo, useCallback, useEffect, useMemo, useState} from "react";
import Page from "../common/Page";
import Nav from "../common/Nav";
import styles from "./Files.module.scss";
import {useDialog} from "../common/Dialog";
import {FolderIcon, NewFolderIcon} from "../common/Icons";
import NewCollectionDialog from "./NewCollectionDialog";
import CollectionButton from "./CollectionButton";
import FilesApi, {Collection, Document} from "../../api/Files";
import FilesList from "./FilesList";

function Files() {
  const {show, toggleShow} = useDialog();

  const [collections, setCollections] =
    useState<Map<string, Collection>>();
  useEffect(() => {
    if (!collections) {
      FilesApi.getAllCollections().then((data) => {
        const collections = new Map();
        data.forEach((entry: Collection) => {
          collections.set(entry.id, entry);
        });
        setCollections(collections);
      });
    }
  }, [setCollections, collections]);

  const onNewCollectionSuccess = useCallback((collection: Collection) => {
    if (!collections) {
      return null;
    }

    setCollections(new Map(collections.set(collection.id, collection)));
    toggleShow();
  }, [collections, setCollections, toggleShow]);

  const [activeCollectionId, setActiveCollectionId] = useState<string>();

  const handleCollectionClick = (id: string | undefined)=>{
    setActiveCollectionId(id);
  }

  const activeCollection = useMemo(
    () => {
    if (!collections || !activeCollectionId) {
      return;
    }

    return collections.get(activeCollectionId);
  }, [collections, activeCollectionId]);
  const activeCollectionDocuments = useMemo(() => {
    const documents = new Map<string, Document>();
    activeCollection?.documents.forEach((document) => {
      documents.set(document.id, document);
    });

    return documents;
  }, [activeCollection]);

  const [allDocuments, setAllDocuments] = useState<Map<string, Document>>();
  useEffect(() => {
    if (!activeCollectionId) {
      FilesApi.getAllDocuments()
        .then((data) => {
          const newMap = new Map<string, Document>();

          data.forEach((document) => newMap.set(document.id, document));

          setAllDocuments(newMap);
        });
    } else {
      FilesApi.getCollection(activeCollectionId)
        .then((data) => {
          if (!collections) {
            return;
          }

          setCollections((latest) => {
            if (!latest) {
              return undefined;
            }

            return new Map(latest.set(data.id, data));
          })
        })
    }
  }, [activeCollectionId]);

  const addDocument = useCallback((document: Document) => {
    if (!activeCollectionId) {
      setAllDocuments((latest) => {
        if (!latest) {
          return undefined;
        }
        return new Map(latest.set(document?.id, document));
      });
    } else {
      setCollections((latest) => {
        if (!latest || !activeCollection) {
          return undefined;
        }
        // document["collectionId"] = activeCollectionId;
        return new Map(latest.set(
          activeCollectionId,
          {...activeCollection, documents: [...activeCollection.documents, document]}
        ));
      });
    }
  }, [activeCollectionId,collections]);// setAllDocuments, setCollections, activeCollection,

  const onFileDelete = useCallback((ids: string[]) => () => {
    if (!activeCollectionId) {
      setAllDocuments((latest) => {
        if (!latest) {
          return undefined;
        }

        ids.forEach((id) => latest.delete(id));
        return new Map(latest);
      });
    } else {
      setCollections((latest) => {
        if (!latest || !activeCollection) {
          return undefined;
        }

        const updatedDocs = activeCollection.documents.filter((document) => {
          return !(ids.some((id) => id === document.id));
        });

        const updated = {...activeCollection, documents: updatedDocs};
        return new Map(latest.set(activeCollectionId, updated));
      });
    }
  }, [activeCollectionId, setAllDocuments, setCollections, activeCollection]);

  const handleCollectionDelete = useCallback(() => {
    if (!activeCollectionId) {
      return;
    }

    FilesApi.deleteCollection(activeCollectionId).then();

    setCollections((latest) => {
      if (!latest) {
        return undefined;
      }

      latest.delete(activeCollectionId);
      return new Map(latest);
    });
    setActiveCollectionId(undefined)
  }, [activeCollectionId, setCollections, setActiveCollectionId]);

  const onCollectionRename = useCallback((updated: Collection) => {
    if (!activeCollectionId) {
      return;
    }

    setCollections((latest) => {
      if (!latest) {
        return undefined;
      }

      latest.set(updated.id, updated);
      return new Map(latest);
    });
  }, [activeCollectionId, setCollections]);

  const handleDocumentMove = useCallback((documents: Document[], targetCollectionId: string) => {
    const targetCollection = collections?.get(targetCollectionId);
    if (!targetCollection) {
      return;
    }

    const updatedTargetIds = new Set<string>();
    targetCollection.documents.forEach((document) => {
      updatedTargetIds.add(document.id);
    });
    documents.forEach((document) => {
      updatedTargetIds.add(document.id);
    })

    FilesApi.updateCollectionDocuments(targetCollectionId, Array.from(updatedTargetIds.values()))
      .then((updated) => {
        setCollections((latest) => {
          if (!latest) {
            return undefined;
          }

          return new Map(latest.set(updated.id, updated));
        })
      });

    documents.forEach((document) => {
      if (!document.collectionId) {
        return;
      }

      FilesApi.getCollection(document.collectionId)
        .then((updated) => {
          setCollections((latest) => {
            if (!latest) {
              return undefined;
            }

            return new Map(latest.set(updated.id, updated));
          })
        });
    });

    if (!activeCollectionId) {
      documents.forEach((document) => {
        setAllDocuments((latest) => {
          if (!latest) {
            return;
          }

          const newDocument = document;
          newDocument.collectionId = targetCollectionId;
          return new Map(latest.set(document.id, newDocument));
        });
      })
    }
  }, [collections, setCollections, activeCollectionId, setAllDocuments]);

  if (!collections || !allDocuments) {
    return null;
  } 
  return (
    <Page>
      <Nav/>
      <NewCollectionDialog show={show} toggleShow={toggleShow} onSuccess={onNewCollectionSuccess}/>

      <div className={styles.main}>
        <div className={styles.inner}>
          <div className={styles.header}>
            <div className={styles.headerSection}>
              <div className={styles.icon}>
                <FolderIcon color="black" size={32}/>
              </div>

              ファイル管理
            </div>

            <div className={styles.headerSection}>
              {/* Unimplemented search bar
                <div className={styles.search}>
                  <input
                    className={styles.input}
                    value={inputValue}
                    onChange={onChange}
                    placeholder="サーチ"
                  />
                  <div className={styles.searchIcon}>
                    <SearchIcon color="black" size={16}/>
                  </div>
                </div>
              */}
            </div>
          </div>

          <div className={styles.body}>
            <div className={styles.left}>
              <button className={styles.newCollection} onClick={toggleShow}>
                <NewFolderIcon size={16} color="black"/>
                新コレクション追加
              </button>

              <div className={styles.collections}>
                <CollectionButton isActive={!activeCollectionId} onClick={()=>{
                  handleCollectionClick(undefined)
                }}/>

                {
                  Array.from(collections.values()).map((collection) => {
                    return (
                      <CollectionButton
                        key={collection.id}
                        isActive={activeCollectionId === collection.id}
                        collection={collection}
                        onClick={()=>{
                          handleCollectionClick(collection.id)
                        }}
                      />
                    )
                  })
                }
              </div>
            </div>

            <div className={styles.right}>
              <FilesList
                activeCollection={activeCollection}
                addDocument={addDocument}
                documents={activeCollection ? activeCollectionDocuments : allDocuments}
                onCollectionDelete={handleCollectionDelete}
                onDocumentDelete={onFileDelete}
                onRename={onCollectionRename}
                handleDocumentMove={handleDocumentMove}
                collections={Array.from(collections.values())}
              />
            </div>
          </div>
        </div>
      </div>
    </Page>
  );
}

export default memo(Files);