import React,{useState,useEffect} from "react";
import styles from "./ChatMessage.module.scss";
import UserIcon from "../../assets/TEMP_USER.png";
import BotIcon from "../../assets/GPT-message-icon.svg";
import clsx from "clsx";
import ChatSourceElement from "./ChatSourceElement";
import {MessageType, Source} from "../../api/Chat";

interface ChatMessageElementProps {
  type: MessageType;
  message: string;
  sources?: any;
}

function ChatMessageElement({ type, message, sources }: ChatMessageElementProps) {
  const classes = clsx(styles.message, {
    [styles.bot]: type === "AI"
  });
  return (
    <div className={classes}>
      <img className={type === "human" ?styles.UserIcon:styles.icon} src={type === "human" ? UserIcon : BotIcon} alt=""/>
      <div className={styles.text}>
        {message}
        {sources?.length > 0 && <ChatSourceElement sources={sources} />}
      </div>
    </div>
  );
}

export default ChatMessageElement;