import axios, { AxiosResponse, AxiosError } from 'axios';
import CounterStore from '../stores/CounterStore';
import { ConfigVariable } from './axiosRequestUrl'
type RequestMethod = 'GET' | 'POST';

interface RequestOptions {
  url: string;
  data?: Record<string, any>;
  method: RequestMethod;
  headers?: any;
}

export default function axiosRequest(options: RequestOptions): Promise<any> {
  const { url, data, method, headers } = options;
const axiosInstance = axios.create({
   baseURL: "/api/",
  //  timeout: 300000, // Request timeout in milliseconds
});
  // Request interceptor
  axiosInstance.interceptors.request.use(
    (config) => {
     let _ConfigVariable:any =  JSON.stringify(ConfigVariable);
      let configObject = JSON.parse(_ConfigVariable);
      // Perform some operations before sending the request, e.g., add request headers
      // config.headers = headers
      config.headers.Authorization = 'Bearer ' + configObject.Token;
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  // Response interceptor
  axiosInstance.interceptors.response.use(
    (response: AxiosResponse) => {
      // Perform some operations before accessing response data
      if (response.data.code === 1) {
        CounterStore.setNotificationSwith( {...response.data,open:true,type:'warning'})
      }
      return response.data;
    },
    (error: AxiosError) => {
      // Check if the error is a timeout error
      if (error.code === "ECONNABORTED") {
        // Handle timeout error here
        CounterStore.setNotificationSwith( {message:'request timeout',code:1,open:true,type:'warning'})
        // You can perform specific actions or return a custom error object here
      } else {
        // Handle other errors
        console.error('Request error:', error.message);
      }
      return Promise.reject(error);
    }
  );

  const axiosConfig = {
    url,
    method,
    // headers,   
    params: method === 'GET' ? data : undefined,
    data: method === 'POST' ? data : undefined,
  };

  // Send the request using axiosInstance
  return axiosInstance(axiosConfig);
}