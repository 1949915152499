import React, {memo, useCallback, useEffect, useRef, useState} from "react";
import styles from "./Chat.module.scss";
import SendIcon from "../../assets/send.svg";
import ChatApi, {BasicMessage, ChatLog, Message, parseSource} from "../../api/Chat";
import ChatMessageElement from "./ChatMessageElement";
import {EventStreamContentType, fetchEventSource} from "@microsoft/fetch-event-source";
import {useAuth0} from "@auth0/auth0-react";
import {Collection} from "../../api/Files";
import {Source} from "../../api/Chat";
import { observer } from "mobx-react-lite"


interface ChatProps {
  addLogToHistory: (log: ChatLog) => void;
  addMessage: (message: BasicMessage | Message) => void;
  log: ChatLog;
  websocketInputVaule:string;
  selectedCollection?: Collection | null;
}
function Chat({ addLogToHistory, addMessage, log,websocketInputVaule, selectedCollection }: ChatProps) {
  const {getAccessTokenWithPopup, getAccessTokenSilently} = useAuth0();
  const [pendingMessage, setPendingMessage] = useState<BasicMessage>();
  const [readyToAdd, setReadyToAdd] = useState(false);
  const windowRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLTextAreaElement>(null);
  const [inputValue, setInputValue] = useState("");
  const onChange = (e: any) => {
    setInputValue(e);
  };
  useEffect(() => {
    if(websocketInputVaule && !pendingMessage){
      addMessage({ type: "human", text: websocketInputVaule });
      setPendingMessage({ type: "AI", text: "" });
      if (!log.id) {
        ChatApi.createConversation()
          .then((newLog) => {
            addLogToHistory(newLog);
            messageSSEStream(newLog.id,websocketInputVaule).then();
          })
      } else {
        messageSSEStream(log.id,websocketInputVaule).then();
      }
    }
  }, [websocketInputVaule]);
  const messageSSEStream = useCallback(async (logId: string,value?:any) => {
    const token = await getAccessTokenSilently({
      authorizationParams: {
        scope: "all"
      },
    }).then((data) => {
      return data;
    }).catch(async (error) => {
      await getAccessTokenWithPopup({
        authorizationParams: {
          scope: "all"
        },
      }).then((data) => {
        
        return data;
      })
    });
    const collectionId = selectedCollection?.id;

    await fetchEventSource(`/api/prompt/${logId}/source`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`
      },
      body: JSON.stringify({
        message: value??inputValue,
        collectionId: collectionId?.trim()
      }
      ),
      onmessage: (msg) => {
        if (msg.event === "source") {
          const source: Source = parseSource(JSON.parse(msg.data));
          setPendingMessage((latest: BasicMessage | undefined) => {
            if (latest) {
              const toSet = {...latest, sources: latest.sources ? [...latest.sources, source] : [source]};
              return toSet;
            }
          });
        } else {
          setPendingMessage((latest: BasicMessage | undefined) => {
            if (latest) {
              return {...latest, text: latest.text + msg.data};
            }
          });
        }
      },
      onclose: () => {
        setReadyToAdd(true);
      },
      async onopen(response) {
        if (response.ok && response.headers.get('content-type') === EventStreamContentType) {
          return;
        } else if (response.status >= 400 && response.status < 500 && response.status !== 429) {
          // throw new FatalError();
        } else {
          // throw new RetriableError();
        }
      },
    });
  }, [getAccessTokenSilently, inputValue, getAccessTokenWithPopup, addMessage, setPendingMessage, setReadyToAdd]);

  useEffect(() => {
    if (readyToAdd && pendingMessage) {
      addMessage(pendingMessage);
      setReadyToAdd(false);
      setPendingMessage(undefined);
    }
  }, [readyToAdd, pendingMessage]);

  const [isComposing, setIsComposing] = useState(false);
  const handleIsComposing = useCallback((toSet: boolean) => () => {
    setIsComposing(toSet);
  }, []);

  const handleSend = (inputValue:any) => {
    if (inputValue === "") { return; }
    addMessage({ type: "human", text: inputValue });
    setInputValue("");
    setPendingMessage({ type: "AI", text: "" });
    if (!log.id) {
      ChatApi.createConversation()
        .then((newLog) => {
          addLogToHistory(newLog);
          messageSSEStream(newLog.id).then();
        })
    } else {
      messageSSEStream(log.id).then();
    }
  }

  const onKeyDown = useCallback((e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.repeat) {
      return;
    }
    
    if(e.key === "Enter" && !e.shiftKey && !isComposing) {
      e.preventDefault();
      handleSend(inputValue);
    }
  }, [handleSend, isComposing]);

  useEffect(() => {
    if (windowRef.current !== null) {
      windowRef.current.scrollTop = windowRef.current.scrollHeight - 100;
    }

    if (!pendingMessage) {
      inputRef.current!.focus();
    }
  }, [windowRef, log, pendingMessage, inputRef]);
  return (
    <div className={styles.chat}>
      <div className={styles.window} ref={windowRef}>
        {log?.messages?.map((message, index) =>
            <ChatMessageElement key={index} type={message.type} message={message.text} sources={message.sources}/>
        )}

        {
          pendingMessage && (
            <ChatMessageElement type="AI" message={pendingMessage.text} sources={pendingMessage.sources}/>
          )
        }
      </div>
      <div className={styles.inputWrapper}>
        <textarea
          className={styles.input}
          placeholder="メッセージを書いてください"
          value={inputValue}
          onChange={(e:any)=>onChange(e.currentTarget.value)}
          onKeyDown={onKeyDown}
          onCompositionStart={handleIsComposing(true)}
          onCompositionEnd={handleIsComposing(false)}
          disabled={!!pendingMessage}
          ref={inputRef}
        ></textarea>
        <img className={styles.send} src={SendIcon} alt="Send" onClick={()=>handleSend(inputValue)}/>
      </div>
    </div>
  );
}
export default observer(Chat)