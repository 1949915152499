import {ReactNode, useEffect, useState} from "react";
import {backend} from "./AxiosSetup";
import { useAuth0 } from "@auth0/auth0-react";
import {ConfigVariable} from './axiosRequestUrl'
interface AxiosInterceptorProps {
  children: ReactNode;
}

function AxiosInterceptor({ children }: AxiosInterceptorProps) {
  const [isSet, setIsSet] = useState(false);
  const { getAccessTokenSilently, getAccessTokenWithPopup } = useAuth0();
  useEffect(() => {
    const reqInterceptor = async (config: any) => {
      const token = await getAccessTokenSilently({
        authorizationParams: {
          scope: "all"
        },
      }).then((data) => {      
        return data;
      }).catch(async (error) => {
        await getAccessTokenWithPopup({
          authorizationParams: {
            scope: "all"
          },
        }).then((data) => {        
          return data;
        })
      });
      ConfigVariable.Token = token
      config.headers.Authorization = `Bearer ${token}`;
      return config;
    }

    const errInterceptor = (error: any) => {
      return Promise.reject(error);
    }

    const interceptor = backend.interceptors.request.use(reqInterceptor, errInterceptor);

    setIsSet(true);

    return () => backend.interceptors.request.eject(interceptor);
  }, [getAccessTokenSilently, getAccessTokenWithPopup, setIsSet]);

  if (!isSet) {
    return null;
  }

  return <>{children}</>;
}

export default AxiosInterceptor;