import {NewFolderIcon} from "../common/Icons";
import Dialog from "../common/Dialog";
import React, {useCallback, useState} from "react";
import styles from "./NewCollectionDialog.module.scss";
import FilesApi, {Collection} from "../../api/Files";

interface NewCollectionDialogProps {
  show: boolean;
  toggleShow: () => void;
  onSuccess: (newCollection: Collection) => void;
}

function NewCollectionDialog({ show, toggleShow, onSuccess }: NewCollectionDialogProps) {
  const [inputValue, setInputValue] = useState("");
  const onChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.currentTarget.value);
  }, [setInputValue]);

  const onSubmit = useCallback(() => {
    if (!inputValue) {
      return;
    }

    FilesApi.createCollection(inputValue, []).then((data) => {
      onSuccess(data);
      setInputValue("");
    });
  }, [inputValue, onSuccess, setInputValue]);

  return (
    <Dialog
      headerIcon={NewFolderIcon}
      headerText="新コレクションを追加します"
      title="タイトルを入力してください"
      onSubmit={onSubmit}
      onClose={toggleShow}
      show={show}
      submitText="追加"
      submitIcon={NewFolderIcon}
      submitIsDisabled={!inputValue}
    >
      <input className={styles.input} onChange={onChange} value={inputValue} placeholder="Enter a name"/>
    </Dialog>
  );
}

export default NewCollectionDialog;