import React, { useCallback, useEffect } from 'react';
import './index.css';
import warning from '../../assets/warning.svg';
import error from '../../assets/error.svg';
import success from '../../assets/success.svg';
import CounterStore from '../../stores/CounterStore';
import { observer } from 'mobx-react-lite';
const iconMap: Record<string, string> = {
  warning: warning,
  error: error,
  success: success,
};
export interface NotificationProps {
  // type: 'success' | 'info' | 'warning' | 'error'; // Notification type
  duration?: number; // Notification display time (milliseconds), optional
}

const Notification: React.FC<NotificationProps> = ({ duration = 5000 }) => {
  let NotificationSwith = CounterStore.state.NotificationSwith
  const { open,type,message } = NotificationSwith || {}
  const closeNotification = useCallback(() =>  CounterStore.setNotificationSwith({ open: false }), []);
  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (open) {
      timer = setTimeout(() => {
        closeNotification();
      }, duration);
    }

    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [duration,open,]);
  const iconSrc = iconMap[type];
  return (
    <div className={`notification ${open ? 'NotificationVisible' : 'NotificationHidden'}`}>
      <div className="notification-content">
        {iconSrc && <img src={iconSrc} alt={type} />}
        <span className="notification-message">{message}</span>
        <button className="close-button" onClick={()=>CounterStore.setNotificationSwith( {open:false})}>
          &times;
        </button>
      </div>
    </div>
  );
};

export default observer(Notification);
