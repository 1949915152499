import {FileIcon, MoveFolderIcon} from "./Icons";
import styles from "./CollectionSelectionDialog.module.scss";
import clsx from "clsx";
import Dialog from "./Dialog";
import React, {useCallback, useEffect, useState} from "react";
import {Collection} from "../../api/Files";

interface CollectionSelectionDialogProps {
  collections: Collection[];
  headerText: string;
  onSubmit: (id: string) => void;
  show: boolean;
  toggleShow: () => void;
}

function CollectionSelectionDialog(
  { collections, headerText, onSubmit, show, toggleShow }: CollectionSelectionDialogProps
) {
  const [selectedCollectionId, setSelectedCollectionId] =
    useState<string>();
  const handleCollectionClick = useCallback((id: string) => () => {
    setSelectedCollectionId(id);
  }, [setSelectedCollectionId]);

  const handleSubmit = useCallback(() => {
    if (!selectedCollectionId) {
      return;
    }

    onSubmit(selectedCollectionId);
    toggleShow();
  }, [selectedCollectionId, onSubmit, toggleShow]);

  useEffect(() => {
    if (show) {
      setSelectedCollectionId(undefined);
    }
  }, [show, setSelectedCollectionId]);

  return (
    <Dialog
      headerIcon={FileIcon}
      headerText={headerText}
      title="コレクションを選択してください"
      onSubmit={handleSubmit}
      onClose={toggleShow}
      show={show}
      submitText="移動"
      submitIcon={MoveFolderIcon}
      submitIsDisabled={!selectedCollectionId}
    >
      <div className={styles.collections}>
        {
          collections.length === 0 && (
            <div className={styles.none}>コレクションがありません</div>
          )
        }

        {
          collections.map((collection, index: number) => {
            const collectionClasses = clsx(styles.collection, {
              [styles.collectionGrey]: (index + 1) % 2 === 0,
              [styles.active]: collection.id === selectedCollectionId
            });

            return (
              <div
                key={collection.id}
                className={collectionClasses}
                onClick={handleCollectionClick(collection.id)}
              >
                <input
                  type="radio"
                  checked={selectedCollectionId === collection.id}
                  onChange={() => {}}
                />
                {collection.name}
              </div>
            );
          })
        }
      </div>
    </Dialog>
  );
}

export default CollectionSelectionDialog;