import styles from "./FilesList.module.scss";
import FileRow from "./FileRow";
import React, {useCallback, useRef, useState} from "react";
import FilesApi, {Collection, Document} from "../../api/Files";
import ItemHeader from "../common/ItemHeader";
import {useDialog} from "../common/Dialog";
import CollectionSelectionDialog from "../common/CollectionSelectionDialog";

interface FilesListProps {
  activeCollection?: Collection;
  addDocument: (document: Document) => void;
  documents: Map<string, Document>;
  onCollectionDelete: () => void;
  onDocumentDelete: (id: string[]) => () => void;
  onRename: (updated: Collection) => void;
  handleDocumentMove: (document: Document[], targetCollectionId: string) => void;
  collections: Collection[];
}

function FilesList(
  {
    activeCollection,
    addDocument,
    documents,
    onCollectionDelete,
    onDocumentDelete,
    onRename,
    handleDocumentMove,
    collections
  }: FilesListProps
) {
  const handleRename = useCallback((newName: string) => {
    if (!activeCollection) {
      return;
    }

    FilesApi.renameCollection(activeCollection.id, newName).then((updated) => {
      onRename(updated);
    });
  }, [activeCollection, onRename]);

  const allCheckRef = useRef<HTMLInputElement>(null);
  const [multiselectDocumentIds, setMultiselectDocumentIds] =
    useState<string[]>([]);
  const handleCheckboxClick = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target;
    if (checked) {
      setMultiselectDocumentIds([...multiselectDocumentIds, name])
    } else {
      const index = multiselectDocumentIds.indexOf(name);
      if (index > -1) {
        const updated = multiselectDocumentIds;
        updated.splice(index, 1);
        setMultiselectDocumentIds([...updated]);
      }
    }

    if (allCheckRef.current) {
      allCheckRef.current.checked = false;
    }
  }, [setMultiselectDocumentIds, multiselectDocumentIds, allCheckRef]);

  const handleAllClick = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const checked = event.target.checked;

    if (checked) {
      setMultiselectDocumentIds(Array.from(documents.keys()));
    } else {
      setMultiselectDocumentIds([]);
    }
  }, [setMultiselectDocumentIds, documents]);

  const hasMultiSelection = multiselectDocumentIds.length > 0;

  const handleDeleteSelectedFiles = useCallback(() => {
    onDocumentDelete(multiselectDocumentIds)();
    const promises = multiselectDocumentIds.map((id) => {
      return FilesApi.deleteDocument(id);
    });

    Promise.all(promises).then();
    setMultiselectDocumentIds([]);

    if (allCheckRef.current) {
      allCheckRef.current.checked = false;
    }
  }, [multiselectDocumentIds, setMultiselectDocumentIds, allCheckRef, onDocumentDelete]);

  const {show, toggleShow} = useDialog();

  const handleMultiMove = useCallback((collectionId: string) => {
    const values: Document[] = [];
    multiselectDocumentIds.forEach((id) => {
      const document = documents.get(id);
      if (document) {
        values.push(document);
      }
    });

    handleDocumentMove(values, collectionId);

    toggleShow();
    setMultiselectDocumentIds([]);
    if (allCheckRef.current) {
      allCheckRef.current.checked = false;
    }
  }, [multiselectDocumentIds, handleDocumentMove, documents, toggleShow, setMultiselectDocumentIds, allCheckRef]);

  return (
    <div className={styles.box}>
      <CollectionSelectionDialog
        collections={collections}
        headerText="選択されたファイルを別のコレクションに移動します"
        onSubmit={handleMultiMove}
        show={show}
        toggleShow={toggleShow}
      />

      <ItemHeader
        name={
          hasMultiSelection
            ? `${multiselectDocumentIds.length}つのファイルが選択されました`
            : activeCollection?.name ?? "すべてのファイル"
        }
        backgroundColor={hasMultiSelection ? "grey" : "white"}
        onRename={activeCollection && !hasMultiSelection ? handleRename : undefined}
        onDelete={
          hasMultiSelection
            ? handleDeleteSelectedFiles
            : activeCollection ? onCollectionDelete : undefined
        }
        deleteWarningText={
          hasMultiSelection
            ? "選択されたファイルを削除してもよろしいですか?"
            : `${activeCollection?.name}とそのすべてのファイルを削除してもよろしいですか?`
        }
        showDeleteButtonText={hasMultiSelection}
        onUpload={hasMultiSelection ? undefined : addDocument}
        onMove={hasMultiSelection ? toggleShow : undefined}
        activeCollection={activeCollection}
      />

      <div className={styles.list}>
        <div className={styles.header}>
          <input type="checkbox" className={styles.checkbox} onChange={handleAllClick} ref={allCheckRef}/>
          <div className={styles.text}>ファイル名</div>
          <div className={styles.text}>アップロード日</div>
          <div className={styles.text}>コレクション</div>
        </div>

        <div className={styles.items}>
          {
            Array.from(documents.values()).map((document) => (
              <FileRow
                key={document.id}
                document={document}
                onDelete={onDocumentDelete([document.id])}
                handleCheckboxClick={handleCheckboxClick}
                checked={multiselectDocumentIds.indexOf(document.id) !== -1}
                onDocumentMove={handleDocumentMove}
                collections={collections}
                hideActions={hasMultiSelection}
              />
            ))
          }
        </div>
      </div>
    </div>
  );
}

export default FilesList;