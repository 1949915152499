import React from "react";

const COLOR_MAPPING = {
  "white": "#FFFFFF",
  "black": "#282828",
  "grey": "#8A8F91",
  "light-grey": "#458EB280",
  "blue": "#62CAFE"
}
export interface IconProps {
  color: "white" | "black" | "grey" | "light-grey" | "blue";
  size: 16 | 32 | 40;
}

export function HomeIcon({ color, size }: IconProps) {
  return (
    <svg
      width={size}
      height={size}
      style={{display: "block"}}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d={"M20 28V21.3333C20 19.1242 18.2091 17.3333 16 17.3333C13.7909 17.3333 12 19.1242 12 " +
          "21.3333V28M10.1408 7.53368L6.67415 10.8078C5.68843 11.7387 5.19557 12.2042 4.84254 " +
          "12.7552C4.52961 13.2437 4.29847 13.7799 4.15822 14.3428C4 14.9778 4 15.6558 4 17.0116V19.4667C4 " +
          "22.4536 4 23.9471 4.5813 25.088C5.09262 26.0915 5.90852 26.9074 6.91205 27.4187C8.05291 28 " +
          "9.54639 28 12.5333 28H19.4667C22.4536 28 23.9471 28 25.0879 27.4187C26.0915 26.9074 26.9074 " +
          "26.0915 27.4187 25.088C28 23.9471 28 22.4536 28 19.4667V17.0116C28 15.6558 28 14.9778 27.8418 " +
          "14.3428C27.7015 13.7799 27.4704 13.2437 27.1575 12.7552C26.8044 12.2042 26.3116 11.7387 25.3259 " +
          "10.8078L21.8592 7.53368C19.7972 5.58623 18.7662 4.61251 17.59 4.24516C16.5547 3.92178 15.4453 " +
          "3.92178 14.41 4.24516C13.2338 4.61251 12.2028 5.58623 10.1408 7.53368Z"}
        stroke={COLOR_MAPPING[color]}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"/>
    </svg>
  );
}
export function FolderIcon({ color, size }: IconProps) {
  return (
    <svg
      width={size}
      height={size}
      style={{display: "block"}}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d={"M2.66669 12.5333C2.66669 9.54639 2.66669 8.05291 3.24799 6.91205C3.75931 5.90852 4.57521 " +
          "5.09262 5.57874 4.5813C6.7196 4 8.21307 4 11.2 4H11.6208C12.2469 4 12.56 4 12.8442 4.0863C13.0957 " +
          "4.1627 13.3298 4.28796 13.5329 4.45491C13.7623 4.64349 13.936 4.90398 14.2833 5.42496L15.05 " +
          "6.57504C15.3974 7.09602 15.571 7.35651 15.8005 7.54509C16.0036 7.71204 16.2376 7.8373 16.4892 " +
          "7.9137C16.7734 8 17.0865 8 17.7126 8H20.8C23.787 8 25.2804 8 26.4213 8.5813C27.4248 9.09262 " +
          "28.2407 9.90852 28.7521 10.9121C29.3334 12.0529 29.3334 13.5464 29.3334 16.5333V19.4667C29.3334 " +
          "22.4536 29.3334 23.9471 28.7521 25.0879C28.2407 26.0915 27.4248 26.9074 26.4213 27.4187C25.2804 " +
          "28 23.787 28 20.8 28H11.2C8.21307 28 6.7196 28 5.57874 27.4187C4.57521 26.9074 3.75931 26.0915 " +
          "3.24799 25.0879C2.66669 23.9471 2.66669 22.4536 2.66669 19.4667V12.5333Z"}
        stroke={COLOR_MAPPING[color]}
        strokeWidth={size === 16 ? 4 : 2}
        strokeLinecap="round"
        strokeLinejoin="round"/>
    </svg>
  );
}

export function LogoutIcon({ color, size }: IconProps) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d={"M7.74871 12C6.43272 12.987 5.24429 14.132 4.20986 15.4086C4.06995 15.5812 4 15.7906 4 16M7.74871 " +
          "20C6.43272 19.013 5.2443 17.868 4.20986 16.5914C4.06995 16.4188 4 16.2094 4 16M4 16H21.3333M14.6667 " +
          "6.03705C16.082 4.77028 17.9511 4 20 4C24.4183 4 28 7.58172 28 12V20C28 24.4183 24.4183 28 20 " +
          "28C17.9511 28 16.082 27.2297 14.6667 25.963"}
        stroke={COLOR_MAPPING[color]}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function ListIcon({ color, size }: IconProps) {
  return (
    <svg
      width={size}
      height={size}
      style={{display: "block"}}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14 8L2 8"
        stroke={COLOR_MAPPING[color]}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8 12L2 12"
        stroke={COLOR_MAPPING[color]}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14 4H2"
        stroke={COLOR_MAPPING[color]}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function SearchIcon({ color, size }: IconProps) {
  return (
    <svg
      width={size}
      height={size}
      style={{display: "block"}}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d={"M14 14L9.9665 9.9665M9.9665 9.9665C10.811 9.122 11.3333 7.95533 11.3333 6.66667C11.3333 " +
          "4.08934 9.244 2 6.66667 2C4.08934 2 2 4.08934 2 6.66667C2 9.244 4.08934 11.3333 6.66667 " +
          "11.3333C7.95533 11.3333 9.122 10.811 9.9665 9.9665Z"}
        stroke={COLOR_MAPPING[color]}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function NewFolderIcon({ color, size }: IconProps) {
  return (
    <svg
      width={size}
      height={size}
      style={{display: "block"}}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_215_395)">
        <path
          d={"M11.9999 15V12M11.9999 12V9M11.9999 12H8.99988M11.9999 12H14.9999M14.6558 6C14.6342 " +
            "5.29453 14.5696 4.83614 14.3759 4.45603C14.1203 3.95426 13.7123 3.54631 13.2106 " +
            "3.29065C12.6401 3 11.8934 3 10.3999 3H8.85621C8.54314 3 8.38661 3 8.24452 2.95685C8.11873" +
            " 2.91865 8.0017 2.85602 7.90014 2.77255C7.78542 2.67826 7.69859 2.54801 7.52493 2.28752L7.14157" +
            " 1.71248C6.96791 1.45199 6.88108 1.32174 6.76636 1.22745C6.6648 1.14398 6.54778 1.08135 6.42199 " +
            "1.04315C6.2799 1 6.12336 1 5.81029 1H5.59992C4.10645 1 3.35971 1 2.78928 1.29065C2.28751 1.54631 " +
            "1.87956 1.95426 1.6239 2.45603C1.33325 3.02646 1.33325 3.77319 1.33325 5.26667V8.73333C1.33325 " +
            "10.2268 1.33325 10.9735 1.6239 11.544C1.87956 12.0457 2.28751 12.4537 2.78928 12.7094C3.27762 " +
            "12.9582 3.89519 12.994 4.99988 12.9991"}
          stroke={COLOR_MAPPING[color]}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_215_395">
          <rect width={size} height={size} fill={COLOR_MAPPING[color]}/>
        </clipPath>
      </defs>
    </svg>
  );
}

export function UploadIcon({ color, size }: IconProps) {
  return (
    <svg
      width={size}
      height={size}
      style={{display: "block"}}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d={"M13.5 14H2.5M3.99998 5.55359C5.03702 4.15132 6.2485 2.89188 7.60377 1.80634C7.72003 " +
        "1.71322 7.86 1.66666 7.99998 1.66666M12 5.55359C10.9629 4.15132 9.75146 2.89188 8.39619" +
        " 1.80634C8.27993 1.71322 8.13996 1.66666 7.99998 1.66666M7.99998 1.66666V9.99997"}
        stroke={COLOR_MAPPING[color]}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function FileIcon({ color, size }: IconProps) {
  return (
    <svg
      width={size}
      height={size}
      style={{display: "block"}}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d={"M9.33317 1.37168V2.13333C9.33317 3.25343 9.33317 3.81349 9.55116 4.24131C9.7429 4.61763 " +
          "10.0489 4.92359 10.4252 5.11534C10.853 5.33333 11.4131 5.33333 12.5332 5.33333L13.2948 " +
          "5.33333M9.33317 1.37168C9.07081 1.33333 8.75717 1.33333 8.23253 1.33333H6.93317C5.4397 " +
          "1.33333 4.69296 1.33333 4.12253 1.62398C3.62076 1.87964 3.21282 2.28759 2.95715 2.78935C2.6665 " +
          "3.35979 2.6665 4.10652 2.6665 5.6V10.4C2.6665 11.8935 2.6665 12.6402 2.95715 13.2106C3.21282 " +
          "13.7124 3.62076 14.1204 4.12253 14.376C4.69296 14.6667 5.4397 14.6667 6.93317 14.6667H9.0665C10.56 " +
          "14.6667 11.3067 14.6667 11.8771 14.376C12.3789 14.1204 12.7869 13.7124 13.0425 13.2106C13.3332 " +
          "12.6402 13.3332 11.8935 13.3332 10.4V6.43397C13.3332 5.90933 13.3332 5.59569 13.2948 5.33333M9.33317 " +
          "1.37168C9.39698 1.381 9.45775 1.3926 9.51779 1.40701C9.78989 1.47233 10.05 1.58008 10.2886 " +
          "1.72629C10.5577 1.8912 10.7883 2.1218 11.2495 2.58301L12.0835 3.41698C12.5447 3.87819 12.7753 " +
          "4.10879 12.9402 4.3779C13.0864 4.61649 13.1942 4.87661 13.2595 5.14871C13.2739 5.20875 13.2855 " +
          "5.26952 13.2948 5.33333"}
        stroke={COLOR_MAPPING[color]}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function TrashIcon({ color, size }: IconProps) {
  return (
    <svg
      width={size}
      height={size}
      style={{display: "block"}}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d={"M10.6665 4.66667L9.92946 3.19257C9.56401 2.46168 8.81699 2 7.99984 2C7.18268 2 6.43566 2.46168" +
          " 6.07022 3.19257L5.33317 4.66667M2.6665 4.66667H13.3332M3.99984 4.66667H11.9998V10C11.9998 " +
          "11.2425 11.9998 11.8638 11.7968 12.3538C11.5262 13.0072 11.0071 13.5264 10.3537 13.797C9.8636" +
          " 14 9.24235 14 7.99984 14C6.75733 14 6.13607 14 5.64601 13.797C4.99261 13.5264 4.47348 13.0072 " +
          "4.20283 12.3538C3.99984 11.8638 3.99984 11.2425 3.99984 10V4.66667Z"}
        stroke={COLOR_MAPPING[color]}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function PencilIcon({ color, size }: IconProps) {
  return (
    <svg
      width={size}
      height={size}
      style={{display: "block"}}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d={"M2.04411 12.2101C2.05031 11.9588 2.05341 11.8331 2.08378 11.7151C2.11072 11.6104 2.15338 " +
          "11.5105 2.21029 11.4188C2.27447 11.3153 2.36296 11.2264 2.53993 11.0487L11.2417 2.30864C11.5908 " +
          "1.95805 12.136 1.8993 12.5511 2.16754C13.0433 2.48553 13.4638 2.9034 13.7856 3.39431L13.8082 " +
          "3.42871C13.8245 3.45358 13.8327 3.46601 13.8396 3.47722C14.0951 3.88885 14.041 4.42196 13.7081 " +
          "4.77337C13.6991 4.78293 13.6886 4.79346 13.6676 4.81451L5.01874 13.5014C4.83516 13.6858 4.74337 " +
          "13.778 4.63623 13.8438C4.54125 13.9022 4.43769 13.9452 4.32937 13.9712C4.2072 14.0005 4.07739 " +
          "14.0003 3.81777 13.9999L2 13.997L2.04411 12.2101Z"}
        stroke={COLOR_MAPPING[color]}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function PlusIcon({ color, size }: IconProps) {
  return (
    <svg
      width={size}
      height={size}
      style={{display: "block"}}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d={"M8 14V8M8 8V2M8 8L2 8M8 8L14 8"}
        stroke={COLOR_MAPPING[color]}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function MoveFolderIcon({ color, size }: IconProps) {
  return (
    <svg
      width={size}
      height={size}
      style={{display: "block"}}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d={"M5.59967 14C4.1062 14 3.35946 14 2.78903 13.7094C2.28727 13.4537 1.87932 13.0457 1.62366 " +
          "12.544C1.33301 11.9735 1.33301 11.2268 1.33301 9.73333V6.26667C1.33301 4.77319 1.33301 4.02646 " +
          "1.62366 3.45603C1.87932 2.95426 2.28727 2.54631 2.78903 2.29065C3.35946 2 4.1062 2 5.59967 " +
          "2H5.81005C6.12312 2 6.27965 2 6.42174 2.04315C6.54753 2.08135 6.66456 2.14398 6.76612 " +
          "2.22745C6.88084 2.32174 6.96767 2.45199 7.14133 2.71248L7.52469 3.28752C7.69835 3.54801" +
          " 7.78518 3.67826 7.8999 3.77255C8.00146 3.85602 8.11848 3.91865 8.24427 3.95685C8.38636" +
          " 4 8.5429 4 8.85597 4H10.3997C11.8931 4 12.6399 4 13.2103 4.29065C13.7121 4.54631 14.12" +
          " 4.95426 14.3757 5.45603C14.6663 6.02646 14.6663 6.77319 14.6663 8.26667M10.7303 10C10.0723" +
          " 10.4935 9.47812 11.066 8.9609 11.7043C8.89094 11.7906 8.85597 11.8953 8.85597 12M10.7303 " +
          "14C10.0723 13.5065 9.47812 12.934 8.9609 12.2957C8.89094 12.2094 8.85597 12.1047 8.85597 " +
          "12M8.85597 12H14.6663"}
        stroke={COLOR_MAPPING[color]}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function NoChatIcon({ color, size }: IconProps) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{display: "block"}}
    >
      <g>
        <path
          d={"M35 20C35 28.2843 28.2843 35 20 35C17.6053 35 15.3416 34.4388 13.3333 33.4408C11.8841 32.7206" +
            " 5 35 5 35C5 35 7.29547 28.1482 6.5592 26.6667C5.56118 24.6584 5 22.3947 5 20C5 11.7157 11.7157 " +
            "5 20 5C28.2843 5 35 11.7157 35 20Z"}
          stroke={COLOR_MAPPING[color]}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d={"M16 24L20 20M20 20L24 16M20 20L16 16M20 20L24 24"}
          stroke={COLOR_MAPPING[color]}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
}

export function DownCaretIcon({ color, size }: IconProps) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{display: "block"}}
    >
      <path
        d={"M4 6.13916C5.06206 7.60104 6.30708 8.91044 7.70212 10.0334C7.87737 10.1744 8.12263 10.1744 " +
          "8.29788 10.0334C9.69292 8.91044 10.9379 7.60104 12 6.13916"}
        stroke={COLOR_MAPPING[color]}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function CheckmarkIcon({ color, size }: IconProps) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{display: "block"}}
    >
      <path
        d={"M2.66669 8.74949L6.23422 12.3132L6.51926 11.8148C8.04753 9.14247 10.1625 6.85196 12.7048 " +
          "5.11595L13.3334 4.68677"}
        stroke={COLOR_MAPPING[color]}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
