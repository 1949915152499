import React, {ReactNode, useCallback, useState} from "react";
import styles from "./ItemHeader.module.scss";
import clsx from "clsx";
import {MoveFolderIcon, UploadIcon,PencilIcon, TrashIcon} from "./Icons";
import FileUploadButton from "../files/FileUploadButton";
import {Collection, Document} from "../../api/Files";
import UploadAudio from './UploadAudio'
interface ItemHeaderProps {
  ws?:any,
  name: string;
  activeLog?: any;
  messageList?: any[];
  setMessageList?:any,
  backgroundColor: "white" | "grey";
  onRename?: (updated: string) => void;
  onDelete?: () => void;
  onLogClick?:any;
  deleteWarningText?: string;
  showDeleteButtonText?: boolean;
  setSocket?:any,
    socket?:any,
    getAllmenu?:any,
  onUpload?: (document: Document) => void;
  onMove?: () => void;
  activeCollection?: Collection;
  children?: ReactNode; // Custom actions to be added to the button group
}

function ItemHeader(
  {
    setSocket,
    socket,
    name,
    onLogClick,
    activeLog,
    getAllmenu,
    messageList,
    setMessageList,
    backgroundColor,
    onRename,
    onDelete,
    deleteWarningText,
    showDeleteButtonText,
    onUpload,
    onMove,
    activeCollection,
    children
  }: ItemHeaderProps
) {
  const [editingName, setEditingName] =
    useState(false);
  const [showDeleteWarning, setShowDeleteWarning] = useState(false);
  const toggleDeleteWarning = useCallback(() => {
    setShowDeleteWarning(!showDeleteWarning);
  }, [showDeleteWarning, setShowDeleteWarning]);
  const [inputValue, setInputValue] =
    useState(name);
  const onChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
  }, [setInputValue]);
  const startEditing = useCallback(() => {
    setInputValue(name);
    setEditingName(true);
  }, [name, setInputValue, setEditingName]);
  const handleCancelRename = useCallback(() => {
    setInputValue(name);
    setEditingName(false);
  }, [setInputValue, setEditingName, name]);

  const handleNameSave = useCallback(() => {
    if (!inputValue || !onRename) {
      return;
    }
    onRename(inputValue);
    setEditingName(false);
     getAllmenu&& onLogClick(activeLog.id)
    getAllmenu && getAllmenu('edit')
   
    
    
  }, [inputValue, onRename, setEditingName]);
  if (activeLog) {
   onLogClick(activeLog.id) 
  }
  const handleDelete = useCallback(() => {
    if (!onDelete) {
      return;
    }

    onDelete();
    setShowDeleteWarning(false);
    getAllmenu&& getAllmenu()
  }, [onDelete, setShowDeleteWarning]);

  const saveButtonClasses = clsx(styles.button, styles.black, {
    [styles.disabled]: inputValue === ""
  });

  const headerClasses = clsx(styles.header, {
    [styles.outline]: editingName,
    [styles.backgroundGrey]: backgroundColor === "grey"
  });

  if (showDeleteWarning) {
    return (
      <div className={headerClasses}>
        {deleteWarningText}

        <div className={styles.buttons}>
          <button
            className={clsx(styles.button, styles.grey)}
            onClick={toggleDeleteWarning}
          >キャンセル</button>
          <button className={clsx(styles.button, styles.red)} onClick={handleDelete}>削除</button>
        </div>
      </div>
    );
  }

  if (editingName) {
    return (
      <div className={headerClasses}>
        <input className={styles.input} onChange={onChange} value={inputValue}/>

        <div className={styles.buttons}>
          <button className={clsx(styles.button, styles.grey)} onClick={handleCancelRename}>キャンセル</button>
          <button className={saveButtonClasses} onClick={handleNameSave}>保存</button>
        </div>
      </div>
    );
  }

  return (
    <div className={headerClasses}>
      {name}

      <div className={styles.flex}>
        {
          onMove && (
            <button className={styles.button} onClick={onMove}>
              <MoveFolderIcon color="black" size={16}/>
              コレクションに移動
            </button>
          )
        }
         {/* { (onRename && messageList && messageList.length < 1) &&  (
          <UploadAudio messageList={messageList}  setMessageList={setMessageList} socket={socket} setSocket={setSocket}/>
        )} */}
        { onRename && (
          <button className={styles.button} onClick={startEditing}>
            <PencilIcon color="black" size={16}/>
          </button>
        )}
        { onDelete && (
          <button className={styles.button} onClick={toggleDeleteWarning}>
            <TrashIcon color="black" size={16}/>
            {showDeleteButtonText ? "消去" : ""}
          </button>
        )}
        { onUpload && (
          <FileUploadButton onUpload={onUpload} activeCollection={activeCollection}/>
        )}
        {children}
      </div>
    </div>
  );
}

export default ItemHeader;