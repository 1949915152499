import {ReactNode, useEffect} from "react";
import styles from "./Page.module.scss";
import UserApi from "../../api/User";
import {useAuth0} from "@auth0/auth0-react";

interface PageProps {
  children: ReactNode;
}

function Page({ children }: PageProps) {
  const {isAuthenticated, isLoading} = useAuth0();
  useEffect(() => {
    if (isAuthenticated) {
      UserApi.updateUser();
    }
  }, [isAuthenticated]);

  if (isLoading) {
    return null;
  }

  return (
    <div className={styles.page}>
      {children}
    </div>
  )
}

export default Page;