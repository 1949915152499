import React, {StrictMode} from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import Home from "./components/home/Home";
import {Auth0Provider} from "@auth0/auth0-react";
import {AuthenticationGuard} from "./authentication-guard";
import {createBrowserRouter, RouterProvider} from "react-router-dom";
import AxiosInterceptor from "./api/AxiosInterceptor";
import Files from "./components/files/Files";
import ErrorBoundary from "./components/ErrorBoundary";
import Notification from './components/Notification'
const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const router = createBrowserRouter([
  {
    path: "/",
    element: <AuthenticationGuard component={Home}/>,
  },
  {
    path: "/files",
    element: <AuthenticationGuard component={Files}/>,
  },
]);
console.log(process.env);

root.render(
    <Auth0Provider
       domain={process.env.REACT_APP_AUTH0_URL||''}
    clientId={process.env.REACT_APP_AUTH0_PARMS||''}
    
    //  domain={'kuebiko.jp.auth0.com'}
      // clientId={'hjXRQI8dr8nloMg2ta877zq11ISRxjAV'}
      authorizationParams={{
        audience:process.env.REACT_APP_AUTH0_AUDIENCE||'',
        redirect_uri: window.location.origin
      }}
    >
      <AxiosInterceptor>
        <StrictMode> 
        <ErrorBoundary>
            <Notification/>
            <RouterProvider router={router} />  
          </ErrorBoundary>
        </StrictMode>
      </AxiosInterceptor>
    </Auth0Provider>
);
