import {memo} from "react";
import styles from "./CollectionButton.module.scss";
import {Collection} from "../../api/Files";
import {FolderIcon, ListIcon} from "../common/Icons";
import clsx from "clsx";

interface CollectionButtonProps {
  collection?: Collection;
  isActive: boolean;
  onClick: () => void;
}

function CollectionButton({ collection, isActive, onClick }: CollectionButtonProps) {
  const classes = clsx(styles.button, {
    [styles.active]: isActive
  });

  return (
    <button className={classes} onClick={onClick}>
      {
        !collection
          ? <ListIcon size={16} color={isActive ? "white" : "black"}/>
          : <FolderIcon size={16} color={isActive ? "white" : "black"}/>
      }

      { collection ? collection.name : "すべてのファイル" }
    </button>
  );
}

export default memo(CollectionButton);