import React, {ChangeEvent, useCallback} from "react";
import styles from "./FileUploadButton.module.scss";
import FilesApi, {Collection, Document} from "../../api/Files";
import { UploadIcon } from "../common/Icons";
import {uploadDocument} from '../../api'

interface FileUploadButtonProps {
  onUpload: (document: Document) => void;
  activeCollection?: Collection;
}

function FileUploadButton({ onUpload, activeCollection }: FileUploadButtonProps) {
  const handleFileChange = async(event: ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files || !event.target.files[0]) {
      return;
    }

    const formData = new FormData();
    formData.append("document", event.target.files[0]);

    if (activeCollection) {
      formData.append("collection_id", activeCollection.id);
    }
    const result = await uploadDocument(formData)
    if (result?.code !== 1) {
        // FilesApi.getAllCollections()
       const date = new Date(result.created);
      const chinaTime = new Date(date.toLocaleString("en-US", { timeZone: "Asia/Shanghai" }));
      result.created = chinaTime     
       onUpload(result);
    }
  }
  const handleFileClear = useCallback((event: any) => {
    event.target.value = '';
  }, []);

  return (
    <div className={styles.button}>
      <input
        type="file"
        className={styles.input}
        onChange={handleFileChange}
        onClick={handleFileClear}
        accept= ".pdf, .txt, .xlsx, .pptx"
      />

      <UploadIcon color="white" size={16}/>
      アップロード
    </div>
  );
}

export default FileUploadButton;