import { makeObservable, observable, action } from 'mobx';

class CounterStore {
  state = {
    NotificationSwith: {
      message: '',
      code: '',
      type: '',
      open: false,
    },
   selectedSource:false,
  };
  
 

  constructor() {
    makeObservable(this, {
      state: observable,
      setNotificationSwith: action,
      // decrement: action,
    });
  }

  setNotificationSwith = (parms: any) => {
    this.state.NotificationSwith = parms
  };
   setSelectedSource = (parms: any) => {
    this.state.selectedSource = parms
  };
}

const counterStore = new CounterStore();
export default counterStore;
