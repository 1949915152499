import {backend} from "./AxiosSetup";
import {DocumentFragment} from "../components/home/ChatSourceElement";

export interface ChatLog {
  id: string;
  userId: string;
  title: string;
  messages?: (Message | BasicMessage)[];
  created: Date;
  updated: Date;
}

export type MessageType = "human" | "AI";

export interface BasicMessage {
  text: string;
  type: MessageType;
  sources?: Source[];
}

export interface Message {
  id: string;
  text: string;
  conversationId: string;
  sources?: Source[];
  type: MessageType;
  created: Date;
}

export interface Source {
    document: DocumentFragment;
    chunk: number;
    chunkText: string;
    start: number;
    end: number;
}

const parseDocumentFragment = (data: any): DocumentFragment => {
    return {
        id: data.id,
        filename: data.filename,
        userId: data.userId
    };
};
export const parseSource = (data: any): Source => {
    return {
        document: parseDocumentFragment(data.document),
        chunk: data.chunk,
        chunkText: data.chunkText,
        start: data.start,
        end: data.end
    };
};

const ChatApi = {
  getAllConversations(): Promise<ChatLog[]> {
    return backend
      .get(`/conversations/`)
      .then(({ data }: any) => data.map((entry: any) => ({
        id: entry.id,
        userId: entry.userId,
        title: entry.title,
        messages: [],
        created: entry.created,
        updated: entry.updated
      })));
  },
  createConversation(): Promise<ChatLog> {
    return backend
      .post(`/conversations/`, {})
      .then(({ data }: any) => ({
        id: data.id,
        userId: data.userId,
        title: data.title,
        messages: [],
        created: data.created,
        updated: data.updated
      }));
  },
  getMessages(conversationId: string): Promise<Message[]> {
    return backend
      .get(`/messages/${conversationId}`)
      .then(({ data }) => (data.map((message: any) => {
          const newMessage: Message = {
              id: message.id,
              text: message.text,
              conversationId: message.conversationId,
              sources: message.sources.map((source: any) => parseSource(source)),
              type: message.type,
              created: message.created
          };
          return newMessage;
      })));
  },
  updateConversationTitle(conversationId: string, title?: string): Promise<ChatLog> {
    return backend
      .post(`/conversations/title/${conversationId}`, { title })
      .then(({ data }: any) => ({
        id: data.id,
        userId: data.userId,
        title: data.title,
        messages: [],
        created: data.created,
        updated: data.updated
      }));
  },
  deleteConversation(conversationId: string): Promise<void> {
    return backend
      .delete(`/conversations/${conversationId}`)
      .then();
  }
}

export default ChatApi;