import React, {useCallback, useEffect, useMemo, useState} from 'react';
import styles from './Home.module.scss';
import Chat from "./Chat";
import ChatHistoryButton from "./ChatHistoryButton";
import ChatApi, {BasicMessage, Message, ChatLog} from "../../api/Chat";
import Nav from "../common/Nav";
import Page from "../common/Page";
import {NoChatIcon, PlusIcon} from "../common/Icons";
import ItemHeader from "../common/ItemHeader";
import {Collection} from "../../api/Files";
import UpArrow from "../../assets/up-arrow.svg";
import ChatCollectionSelector from "./ChatCollectionSelector";
import Tooltip from './Tooltip'
 import CounterStore from '../../stores/CounterStore';
import Notify from './Notify'
function Home() {
  
  const [logHistory, setLogHistory] =
    useState<Map<string, ChatLog>>();
    const [menuList, setMenuList] =
    useState<Map<string, ChatLog>>();
  const [loadingHistory, setLoadingHistory] = useState(false);
  const [activeLogId, setActiveLogId] = useState<string>();
  const [socket, setSocket] = useState<WebSocket | null>(null);
  const [layoutButton,setLayoutButton]= useState(false);
  const [layoutWidth,setLayoutWidth]= useState(0);
  const [messageList,setMessageList] = useState<any>([]);
  const [websocketInputVaule,setWebsocketInputVaule] = useState('');
  const activeLog: ChatLog | undefined|any = useMemo(() => {
    if (!logHistory || !activeLogId) {
      return undefined;
    }
    return logHistory.get(activeLogId);
  }, [logHistory, activeLogId]);
  const addLogToHistory = useCallback((newLog: ChatLog) => {
    setLogHistory(new Map(logHistory?.set(newLog.id, newLog)));
    setActiveLogId(newLog.id);
  }, [logHistory, setLogHistory, setActiveLogId,]);
  useEffect(() => {
    messageList.length>0 ? setLayoutWidth(400) : setLayoutWidth(0)
  }, [messageList]);
  const addMessageToLocalLog = async (message: BasicMessage | Message) => {
    if (!activeLogId || !logHistory) { return; }
    const activeLog = logHistory.get(activeLogId);
    if (!activeLog) { return; }
    if (activeLog.title === "PLACEHOLDER" && message.type === "AI") {
      let newLog = await ChatApi.updateConversationTitle(activeLog.id)
      setLogHistory((latest) => {
        if (!latest || !activeLogId) { return; }

        const activeLog = latest.get(activeLogId);

        if (!activeLog) { return; }

        return new Map(latest.set(newLog.id, {
          ...activeLog,
          title: newLog.title
        }));
      });
    }else{
      let vLogHistory:Map<string, ChatLog> = Object.assign(new Map(),logHistory);
      setLogHistory((latest) => { 
        if (!latest || !activeLogId) { return; }
        const activeLog = latest?.get(activeLogId);
        if (!activeLog) { return; } 
        vLogHistory.set(activeLogId, {
          ...activeLog,
          messages: activeLog.messages
            ? activeLog.messages.concat([message]) 
            : [message],
        })
        return new Map(
          vLogHistory
        );
      });
    }


  };
  const onLogClick = (log: any) => () => {
 CounterStore.setSelectedSource(false)
    setMessageList([])
    socket?.close()
    if (!logHistory || !log.id) { return; }
    ChatApi.getMessages(log.id).then((messages) => {
      setLogHistory(new Map(logHistory.set(log.id, {
        ...log,
        messages: messages
      })));
      setActiveLogId(log.id)
    });
  }
  useEffect(() => {
    if (!logHistory && !loadingHistory) {
      setLoadingHistory(true);
      getAllmenu()
    }
  }, [logHistory, setLogHistory, loadingHistory, setLoadingHistory]);
  const getAllmenu = (type?: any) => {
    // setTimeout(() => {
       ChatApi.getAllConversations().then(async(logs) => {
        const newMenu:any = await new Map<string, ChatLog>();
        logs.forEach((log) => {
          newMenu.set(log.id, log);
        })
        setMenuList(newMenu)
         setLogHistory(newMenu)
        type === 'edit' &&  click(activeLog,newMenu)
       });
  }
  const click = (log: any,newMenu:any) => {
    if (!newMenu || !log?.id) { return; }
    ChatApi.getMessages(log.id).then((messages) => {
      // Check if an item with the same ID exists
      if (newMenu.has(log.id)) {
        // Get the newItem
        const newItem = newMenu.get(log.id);
        // Update the title of log
        log.title = newItem.title;
      }
    // Use the updated log to update logHistory
      setLogHistory(new Map(newMenu.set(log.id, { ...log, messages })));
      setActiveLogId(log.id)
    });
  }
  const onNewConversation = useCallback(() => {
    socket?.close()
    ChatApi.createConversation()
      .then((log) => {
        // setLogHistory(new Map(logHistory?.set(log.id, log)));
        getAllmenu()
        setActiveLogId(log.id);
      });
  }, [setActiveLogId, logHistory, setLogHistory]);

  const handleDelete = useCallback(() => {
    if (!activeLogId) {
      return;
    }

    ChatApi.deleteConversation(activeLogId).then();
    setActiveLogId(undefined);
    setLogHistory((latest) => {
      if (!latest) {
        return;
      }

      latest.delete(activeLogId);
      return new Map(latest);
    });
  }, [activeLogId]);

  const handleRename = useCallback((title: string) => {
    if (!activeLogId) {
      return;
    }

    ChatApi.updateConversationTitle(activeLogId, title)
      .then((updated) => {
        setLogHistory((latest) => {
          if (!latest) {
            return;
          }

          return new Map(latest.set(updated.id, updated));
        })
      });
  }, [activeLogId, setLogHistory]);
  const [chatCollection, setChatCollection] = useState<Collection>();
  return (
    <Page>
      <Nav/>
      <div className={styles.historyContainer}>
        <button className={styles.newChatButton} onClick={onNewConversation}>
          <PlusIcon color="white" size={16}/>
          新会話
        </button>
        {
          (!menuList || menuList.size === 0) && (
            <div className={styles.tooltip}>
              <img src={UpArrow} alt=""/>
              ボタンをクリックして{"\n"}新しいチャットを作成します
            </div>
          )
        }

        <div className={styles.logs}>
          {
            menuList &&
            Array.from(menuList.values())
              .sort((a, b) => a.updated < b.updated ? 1 : -1)
              .map((log) => (
                  <ChatHistoryButton
                    key={log.id}
                    log={log}
                    onClick={onLogClick(log)}
                    isActive={activeLogId === log.id}
                  />
                )
              )
          }
        </div>
      </div>

        {
        !activeLog && (
          <div className={styles.none}>
            <NoChatIcon color="grey" size={40}/>
            表示するチャット{"\n"}はありません
          </div>
        )
      } 
      {
        activeLog && (
          <div className={styles.box}>
  <div className={styles.homeLayout}>
    <div className={styles.layoutHasSider}>
      {/* <div className={styles.layoutSider} style={{flex:`0 0 ${layoutWidth}px`, transition: 'flex 0.3s ease-in-out'}}>
        <div className={styles.layoutSiderCont} onMouseOut={()=>setLayoutButton(false)} onMouseOver={()=>setLayoutButton(true)}>
              {(layoutWidth>100&& layoutButton) && 
              <button className={styles.layoutSiderButton} onClick={()=>setLayoutWidth(1)}>
                <span>&lt;</span>
              </button>}
              {layoutWidth<100 && 
                <button className={styles.layoutSiderButton} onClick={()=>setLayoutWidth(400)}>
                  <span>&gt;</span>
                </button>}
                {layoutWidth>100&&<Notify messageList={messageList}/>}
        </div>
      </div> */}
      <div className={styles.layoutContent}>
      <div className={styles.header}>
                <ItemHeader
                  socket={socket}
                  getAllmenu={getAllmenu}
                      setSocket={setSocket}
                      onLogClick={onLogClick}
                      name={activeLog?.title}
                      activeLog={ activeLog}
                  messageList={messageList}
                  setMessageList={setMessageList}
                  backgroundColor="white"
                  onDelete={handleDelete}
                  deleteWarningText={`${activeLog.title}を削除してもよろしいですか?`}
                  onRename={handleRename}
                >
                  <ChatCollectionSelector
                    onSelect={setChatCollection}
                    selectedCollection={chatCollection}
                  />
                </ItemHeader>
            </div>
            <Chat
            websocketInputVaule={websocketInputVaule}
              addLogToHistory={addLogToHistory}
              addMessage={addMessageToLocalLog}
              log={activeLog}
              selectedCollection={chatCollection}
            />
      </div>
    </div>
    {/* <div className={styles.layoutFooter}>
      <Tooltip setMessageList={setMessageList} messageList={messageList} setWebsocketInputVaule={setWebsocketInputVaule}/>
    </div> */}
  </div>
          </div>
         )} 
    </Page>
  );
}

export default Home;